import * as React from "react";
import { useLayoutEffect } from "./useLayoutEffect";

const useReactId = (React as any)["useId".toString()] || (() => undefined);
let count = 0;

export function useID(deterministicId?: string): string {
  const [id, setId] = React.useState<string | undefined>(useReactId());
  // React versions older than 18 will have client-side ids only.
  useLayoutEffect(() => {
    if (!deterministicId) setId((reactId) => reactId ?? String(count++));
  }, [deterministicId]);
  return deterministicId || id || "";
}

export function labelID(id: string) {
  return `${id}Label`;
}

export function errorTextID(id: string) {
  return `${id}Error`;
}

export function helpTextID(id: string) {
  return `${id}HelpText`;
}

export function characterCounterID(id: string) {
  return `${id}CharacterCounter`;
}

export function prefixID(id: string) {
  return `${id}Prefix`;
}

export function suffixID(id: string) {
  return `${id}Suffix`;
}
