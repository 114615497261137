import * as React from "react";

export function useToggle(initialState: boolean) {
  const [value, setValue] = React.useState(initialState);

  return {
    value,
    toggle: React.useCallback(() => setValue((state) => !state), []),
    setTrue: React.useCallback(() => setValue(true), []),
    setFalse: React.useCallback(() => setValue(false), []),
  };
}
